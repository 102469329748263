@font-face {
  font-family: "Sans Culottes";
  src: url("./fonts/Sans_Culottes.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Inconsolata:wght@200..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Adjust the size of toolbar buttons (prev, next, today, day/week/month) */
.fc .fc-button {
  font-size: 0.7rem; /* Reduce the font size */
  padding: 0.25rem 0.5rem; /* Reduce padding */
  height: auto; /* Allow the button height to shrink */
  border-radius: 0.25rem; /* Smaller border radius for less rounded buttons */
}

/* Adjust the font size of the toolbar title */
.fc-toolbar-title {
  font-size: 1rem; /* Adjust title font size */
  font-weight: 500; /* Adjust font weight */
}

/* Optional: Adjust the calendar header (Day, Date headers) */
.fc .fc-daygrid-day-top {
  font-size: 0.7rem; /* Smaller day label size */
}
